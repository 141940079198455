import http from "../api/http-common";
import HttpUtil from '../api/http-util';
import ReactDOM from 'react-dom';
import AlertOperationRequest from "../components/IoTeUICCs/single-view/rsp-operations/AlertOperationRequest";

export const getDefaultVP_DownloadProfile = 1200;
export const getDefaultVP_EnableProfile = 900;

const getAll = (config) => {
  return http.get("/iot/inventory/eis", config);
};

const getEid = (eid, config) => {
  return http.get("/iot/inventory/eis/search/" + eid, config);
};

const getEidListView = (eid, config) => {
  return http.get("/iot/inventory/eis/search/eid/" + eid + "/listview", config);
};

const getEidByIccidListView = (iccid, config) => {
  return http.get("/iot/inventory/eis/search/eidbyiccid/" + iccid + "/listview", config);
};

const downloadProfile = (eid, accessToken) => {
  let selectedcontactDefaultDP = document.getElementById('contactDefaultDp').checked;
  let activationCode = document.getElementById('activationcode').value;
  let data = { eid: eid };

  const config = HttpUtil.getAuthHeader(accessToken);
  if (selectedcontactDefaultDP === true) {
    data.contactDefaultDp = true;
  } else if (activationCode !== '') {
    data.activationCode = activationCode;
  }

  http.post("/iot/operations/download-profile", data, config).then((response) => {
    showAlert("Info", "Download Profile", "Download Request sent.");
  }).catch((e) => {
    showAlert("Danger", "Download Profile", "Error Downloading Profile - " + e.message);
  });

};

const enableProfile = (eid, accessToken) => {
  let selectedICCID = document.getElementById('selected-iccid').value;
  console.log("selectedICCID", selectedICCID)
  let data = { eid: eid, iccid: selectedICCID};

  const config = HttpUtil.getAuthHeader(accessToken);

  http.post("/iot/operations/enable-profile", data, config).then((response) => {
    showAlert("Info", "Enable Profile", "Enable Profile Request sent.");
  }).catch((e) => {
    showAlert("Danger", "Enable Profile", "Error Enabling Profile - " + e.message);
  });

};

const deleteProfile = (eid, accessToken) => {
  let selectedICCID = document.getElementById('selected-iccid').value;
  console.log("selectedICCID", selectedICCID)
  let data = { eid: eid, iccid: selectedICCID};

  const config = HttpUtil.getAuthHeader(accessToken);

  http.post("/iot/operations/delete-profile", data, config).then((response) => {
    showAlert("Info", "Delete Profile", "Delete Profile Request sent.");
  }).catch((e) => {
    showAlert("Danger", "Delete Profile", "Error Deleting Profile - " + e.message);
  });

};

const getProfileInfoList = (eid, accessToken) => {
  const config = HttpUtil.getAuthHeader(accessToken);
  const data = {eid: eid}
  return http.post("/iot/operations/profileInfoList", data, config).then((response) => {
    showAlert("Info", "Get Profile Info List", "Get Profile Info List Request sent.");
  }).catch((e) => {
    showAlert("Danger", "Get Profile Info List", "Error Getting Profile Info List - " + e.message);
  });
};

const configureImmediateEnable = (eid, toggle, accessToken) => {
  const config = HttpUtil.getAuthHeader(accessToken);

  const data = {eid: eid, immediateEnableFlag: toggle}
  
  return http.post("/iot/operations/configureImmediateEnable", data, config).then((response) => {
    showAlert("Info", "Configure Immediate Enable", "Configure Immediate Enable Request sent.");
  }).catch((e) => {
    showAlert("Danger", "Configure Immediate Enable", "Error Configuring Immediate Enable - " + e.message);
  });
};

// Alert functions
function showAlert(variant, heading, message) {
  ReactDOM.render(
    <AlertOperationRequest variant={variant} heading={heading} message={message} close={hideAlert} />,
    document.getElementById('alert-banner')
  );
  setTimeout(() => hideAlert(), 5000);
}

function hideAlert() {
  ReactDOM.render("", document.getElementById('alert-banner'));
}

function getRequestParams(page, size, search) {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (size) {
    params["size"] = size;
  }

  if (search) {
    params["searchFilter"] = search;
  }

  return params;
};

const functions = {
  getAll,
  getEid,
  getEidListView,
  getEidByIccidListView,
  getRequestParams,
  downloadProfile,
  enableProfile,
  deleteProfile,
  getProfileInfoList,
  configureImmediateEnable
};

export default functions;
