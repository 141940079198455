import React, { Component } from "react";
import {Spinner} from 'react-bootstrap';

import IoTeUICCItem from './IoTeUICCItem';

class IoTeUICCTable extends Component {

  renderTableHeaders(){
    return (
      <thead>
        <tr>
          <th>EID</th>
          <th>Number of Profiles</th>
          <th>Enabled ICCID</th>
        </tr>
      </thead>
    )
  }

  renderEmptyTable(isLoading){
      let loadingContent = <td colSpan="8"><i className="bi bi-exclamation-triangle"></i> No data found</td>;
      if (isLoading){
        loadingContent = <td colSpan="8"><Spinner animation="border" size="sm" /></td>;
      }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody>
            <tr>
              {loadingContent}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderTable(){
    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover" data-tid="eis-table">
            {this.renderTableHeaders()}
            <tbody>
              {
                this.props.content && this.props.content.map((eis, index) => (
                    <IoTeUICCItem eis={eis} key={index}/>
                )) 
              }
            </tbody>
        </table>
      </div>
    )
  }

  render(){ 
    if (this.props.content === null){
      return this.renderEmptyTable(true);

    }else if (this.props.content?.length === 0){
      return this.renderEmptyTable(false);

    }else{
      return this.renderTable();
    }
  }
}

export default IoTeUICCTable;
