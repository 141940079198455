import React, { Component } from "react";

class ProfileItem extends Component {

    renderProfileState(){
        if (this.props.profile.state === "ENABLED"){
            return <span className="badge bg-success text-light"><i className="bi bi-caret-right-fill"></i> {this.props.profile.state}</span>
        }
        else{
            return <span className="badge bg-secondary text-light">{this.props.profile.state}</span>
        }
    }

    render(){
        let rowColor = "";
        if (this.props.profile.state === "ENABLED"){
            rowColor = "success";
        }

        return (
            <tr className={'table-' + rowColor}>
                <td className="align-middle" data-cy="state">{this.renderProfileState()}</td>
                <td className="align-middle" data-cy="iccid">{this.props.profile.iccid}</td>
                {/* <td className="align-middle" data-cy="mnoid">{this.props.entityId}</td> */}
            </tr>
        )
    }
};

export default ProfileItem;
