import React, { Component } from "react";
import {Spinner, OverlayTrigger, Tooltip} from 'react-bootstrap';
import './iotdevice-single.css'

import ProfileItem from './ProfileItem'

class IoTeUICCInfoTable extends Component {

  renderTableHeaders(){
    return (
      <thead>
        <tr>
          <th>State</th>
          <th>Iccid</th>
        </tr>
      </thead>
    )
  }

  renderEmptyTable(isLoading){
    let loadingContent = <td colSpan="8"><i className="bi bi-exclamation-triangle"></i> No data found</td>;
    if (isLoading){
      loadingContent = <td colSpan="8"><Spinner animation="border" size="sm" /></td>;
    }

    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover">
            {this.renderTableHeaders()}
            <tbody>
            <tr>
              {loadingContent}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderTable(){
    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover" data-tid='EisProfileInfoTable'>
            {this.renderTableHeaders()}
            <tbody>
              {
                    this.props.content.profiles.map((profile, index) => (
                    <ProfileItem profile={profile} key={index} openModal={this.props.openModal}/>
                  ))
              }
            </tbody>
        </table>
      </div>
    )
  }

  render(){ 
    var tableHTML = "";

    if (this.props.content === null){
      tableHTML = this.renderEmptyTable(true);

    } else if (this.props.content.length === 0){
      tableHTML = this.renderEmptyTable(false);

    } else{
      tableHTML = this.renderTable();
    }

    return (
      <div className="col-lg-12 d-grid">
        <div className="card shadow mb-4">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold text-primary"><i className="bi bi-box"></i> EIS Information</h6>
            <div className="action-btns">
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-bottom">Refresh EIS Info</Tooltip>}>        
                <button 
                  type="button" 
                  onClick={e => this.props.onRefresh()}
                  data-cy="refresh-eis-button"
                  className="btn btn-circle rps-action-btn btn-info">
                    <i className="bi bi-arrow-counterclockwise fa-sm fa-fw"></i>
                </button>
              </OverlayTrigger>
            </div>
          </div>
          <div className="card-body">
            {/* <!-- profile info table --> */}
            <div className="table-responsive">
              {tableHTML}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IoTeUICCInfoTable;