import React, { Component } from "react";

class IotDeviceItem extends Component {

    render(){
        return <tr>
            <td data-cy="e-id">
            <a href={'/ioteuiccs?eid=' + this.props.eis.eid}>{this.props.eis.eid}</a>
            </td>
            <td data-cy="numberOfProfiles">{this.props.eis.noOfProfiles}</td>
            <td data-cy="enabledICCID">{this.props.eis.enabledIccid}</td>
        </tr>
    }
};
    
export default IotDeviceItem;
