import React, { Component } from "react";
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { withOktaAuth } from '@okta/okta-react';

import "./advance-support.css";

import CallbackPane from '../callback/CallbackPane';

class AdvanceSupport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eid: this.props.eid,
            anchor: this.checkedAnchor(this.props.anchor, this.props.eid),
            oktaAuth: this.props.oktaAuth,
        };
        this.callBackRendered = null;
        this.Rendered = null;

        // reference needed to be able to call function in child components - refresh
        this.callbackPane = React.createRef();
        this.Pane = React.createRef();
    }

    checkedAnchor(anchor, eid) {
        anchor = "callback";
        window.history.replaceState(null, '', 'ioteuiccs?eid=' + eid + "&advance_support=" + anchor);
        return anchor;
    }
    replaceWindowHistory(anchor) {
        window.history.replaceState(null, '', 'ioteuiccs?eid=' + this.state.eid + "&advance_support=" + anchor);
    }

    updateComponent(anchor) {
        switch (anchor) {
            case "callback":
                if (this.callBackRendered === true) {
                    this.callbackPane.current.retrieveCallbacks();
                    this.replaceWindowHistory(anchor);
                }
                break;
            default:
                throw new Error("The anchor received is not supported.");
        }
    }


    createNavLink(eventKey, icon, title, disabled) {
        return (
            <Nav.Link eventKey={eventKey} disabled={disabled}
                onClick={() => this.updateComponent(eventKey)}>
                <i className={"bi " + icon} data-tid={title} id={"nav-link-" + eventKey}></i> {title}
            </Nav.Link>
        );
    }

    handleInitialRender(pageRenderAnchor) {
        let callBackAnchor = null;

        if (pageRenderAnchor === "callback") {
            callBackAnchor = <CallbackPane ref={this.callbackPane} eid={this.state.eid} oktaAuth={this.state.oktaAuth} />;
            this.callBackRendered = true;
        }
        return { callBackAnchor };

    }

    render() {
        var pageRenderAnchor = this.state.anchor;

        let { callBackAnchor } = this.handleInitialRender(pageRenderAnchor);
        return (
            <div className="col-lg-12 d-grid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-danger">
                            <i className="bi bi bi-headset"></i> Advanced Support</h6>
                    </div>
                    <div className="card-body">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.anchor}>
                            <Row>
                                <Col className="border-right-secondary" sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        {this.createNavLink("callback", "bi-check-circle", "Acknowledged Operations", false)}
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content >
                                        <Tab.Pane eventKey="callback">
                                            <div id="callBackTabPane">{callBackAnchor}</div>
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </div >
        );
    }


}

export default withOktaAuth(AdvanceSupport);
