import http from "../api/http-common";

const getCallbacks = (eid, config) => {
  return http.get("/iot/callback/eis/" + eid, config);
};

const deleteCallbackByMessageId = (messageId, config) => {
  return http.delete("/iot/callback/message/" + messageId, config);
}

const getInFlights = (eid, config) => {
  return http.get("/iot/callback/message/" + eid, config);
};

const orderByLatestDate = (content) => {
  content = content.sort(function (a, b) {
    // find a processingStart
    let dateA = new Date();
    if (a.callback !== null){
      dateA = new Date(a.callback.processingStart);
    }

    // find b processingStart
    let dateB = new Date();
    if (b.callback !== null){
      dateB = new Date(b.callback.processingStart);
    }

    return dateB - dateA;
  });

  return content;
}

const functions = {
  getCallbacks,
  deleteCallbackByMessageId,
  getInFlights,
  orderByLatestDate
};



export default functions;
