import React, {Component} from 'react';
import './sidebar.css'
import NewsSideCard from '../../../components/NewsSideCard';
import {withOktaAuth} from '@okta/okta-react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

class Sidebar extends Component {

    hasRole(roleName) {
        const claims = this.props?.authState?.accessToken?.claims;
        if (claims) {
            const claimKeys = Object.keys(claims);
            const roleKey = claimKeys?.find(key => /roles_/.test(key));
            const roles = claims[roleKey];
            let role = "";
            roles?.forEach(element => {
                    if (element.includes(roleName)) {
                        role = element
                    }
                }
            );
            if (role) return true;
        }
        return false;
    }

    render() {

        // TODO consider moving outside render function
        // let { clickMenuOpen, toggled , active} = this.props;
        let {toggled, active} = this.props;
        active = active.toLowerCase();

        const hasM2MRole = this.hasRole("ksm_m2m");
        let profilesLink = (
            <>
                {/* <!-- Nav Item - M2M Profiles --> */}
                <OverlayTrigger placement="bottom" overlay={hasM2MRole ? <></> :
                    <Tooltip id={`tooltip-bottom`}>Licensed feature only: Please contact your kigen account
                        manager</Tooltip>}>
                    <li className={active === "profiles" ? 'nav-item active' : 'nav-item'}>
                        <a className={hasM2MRole ? "nav-link" : "nav-link disabled"} href="/profiles">
                            <i className="bi bi-box"></i>
                            <span data-cy="m2m-profiles-href" className={hasM2MRole ? null : "navItemLinkDisabled"}>Profiles</span></a>
                    </li>
                </OverlayTrigger>
            </>
        );

        let eSimsLink = (
            <>
                {/* <!-- Nav Item - eSIMs --> */}
                <OverlayTrigger placement="bottom" overlay={hasM2MRole ? <></> :
                    <Tooltip id={`tooltip-bottom`}>Licensed feature only: Please contact your kigen account
                        manager</Tooltip>}>
                    <li className={active === "esims" ? 'nav-item active' : 'nav-item'}>
                        <a className={hasM2MRole ? "nav-link" : "nav-link disabled"} href="/esims">
                            <i className="bi bi-cpu"></i>
                            <span data-cy="esims-href"
                                  className={hasM2MRole ? null : "navItemLinkDisabled"}>eSIMs</span></a>
                    </li>
                </OverlayTrigger>
            </>
        );

        const hasConsumerRole = this.hasRole("consumer");
        let consumerLink = (
            <>
                <OverlayTrigger placement="bottom" overlay={hasConsumerRole ? <></> :
                    <Tooltip id={`tooltip-bottom`}>Licensed product only: Please contact your kigen account
                        manager</Tooltip>}>
                    <li className={active === "consumerprofiles" ? 'nav-item active' : 'nav-item'}>
                        <a className={hasConsumerRole ? "nav-link" : "nav-link disabled"} href="/consumerprofiles">
                            <i className="bi-box"></i>
                            <span data-cy="consumer-href" className={hasConsumerRole ? null : "navItemLinkDisabled"}>Profiles</span></a>
                    </li>
                </OverlayTrigger>
            </>
        );

        const hasColdStorageRole = this.hasRole("cold_storage");
        let coldStorageLink = "";
        if (hasColdStorageRole && hasM2MRole) {
            coldStorageLink = (
                <li className={active === "storage" ? 'nav-item active' : 'nav-item'}>
                    <a className={hasColdStorageRole ? "nav-link" : "nav-link disabled"} href="/storage">
                        <i className="bi bi-snow3"></i>
                        <span data-cy="cold-storage-href" className={hasColdStorageRole ? null : "navItemLinkDisabled"}>eSIMs Cold Storage</span>
                    </a>
                </li>
            )

        }

        {/* <!-- Nav Item - Campaigns --> */
        }
        const hasCampaignRole = this.hasRole("ksm_campaign");
        let campaignLink = (<>
            {/* <!-- Nav Item - Campaigns --> */}
            <OverlayTrigger placement="bottom" overlay={hasCampaignRole && hasM2MRole ? <></> :
                <Tooltip id={`tooltip-bottom`}>Licensed feature only: Please contact your kigen account
                    manager</Tooltip>}>
                <li className={active === "campaigns" ? 'nav-item active' : 'nav-item'}>
                    <a className={hasCampaignRole && hasM2MRole ? "nav-link" : "nav-link disabled"} href="/campaigns">
                        <i className="bi bi-lightning-charge"></i>
                        <span data-cy="campaigns-href"
                              className={hasCampaignRole && hasM2MRole ? null : "navItemLinkDisabled"}>Campaigns</span></a>
                </li>
            </OverlayTrigger>
        </>)

        let qrCampaignLink = "";
        const hasQrCampaignRole = this.hasRole("qr_campaign");

        if (hasQrCampaignRole) {
            qrCampaignLink = (
                <OverlayTrigger placement="bottom" overlay={hasQrCampaignRole ? <></> :
                    <Tooltip id={`tooltip-bottom`}>Licensed product only: Please contact your kigen account
                        manager</Tooltip>}>
                    <li className={active === "qrcampaigns" ? 'nav-item active' : 'nav-item'}>
                        <a className={hasQrCampaignRole ? "nav-link" : "nav-link disabled"} href="/qrCampaigns">
                            <i className="bi bi-lightning-charge"></i>
                            <span data-cy="qr-service-href"
                                  className={hasQrCampaignRole ? null : "navItemLinkDisabled"}>QR Service</span>
                        </a>
                    </li>
                </OverlayTrigger>
            )
        }

        const hasIotRSPRole = this.hasRole("iot_rsp");
        let iotRSPLink = (
            <>
                <OverlayTrigger placement="bottom" overlay={hasIotRSPRole ? <></> : <Tooltip id={`tooltip-bottom`}>Licensed product only: Please contact your kigen account manager</Tooltip>}>
                    <li className={active === "ioteuiccs" ? 'nav-item active' : 'nav-item'}>
                        <a className={hasIotRSPRole ?"nav-link" : "nav-link disabled"} href="/ioteuiccs">
                            <i className="bi-box"></i>
                            <span data-cy="iot-euicc-href" className={hasIotRSPRole? null : "navItemLinkDisabled"}>eSIMs</span></a>
                    </li>
                </OverlayTrigger>
            </>
        );

        let iotRSPProfilesLink = (
            <>
                <OverlayTrigger placement="bottom" overlay={hasIotRSPRole ? <></> : <Tooltip id={`tooltip-bottom`}>Licensed product only: Please contact your kigen account manager</Tooltip>}>
                    <li className={active === "iotprofiles" ? 'nav-item active' : 'nav-item'}>
                        <a className={hasIotRSPRole ?"nav-link" : "nav-link disabled"} href="/iotprofiles">
                            <i className="bi-box"></i>
                            <span data-cy="iot-profiles-href" className={hasIotRSPRole? null : "navItemLinkDisabled"}>Profiles</span></a>
                    </li>
                </OverlayTrigger>
            </>
        );

        return (
            <ul className={toggled ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'}
                id="accordionSidebar">

                {/* <!-- Sidebar - Brand --> */}
                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboard">
                    <div className="sidebar-brand-icon ">
                        <img id="kigenWhiteLogo" src="img/kigen-white-logo.svg" alt=""/>
                    </div>
                    {/* <div className="sidebar-brand-text mx-3">Kigen IoT<sup>1.0</sup></div> */}
                </a>

                {/* <!-- Divider --> */}
                <hr className="sidebar-divider my-0"/>

                {/* <!-- Nav Item - Dashboard --> */}
                <li className={active === "home" ? 'nav-item active' : 'nav-item'}>
                    <a className="nav-link" href="/dashboard">
                        <i className="bi bi-house"></i>
                        <span data-cy="home-href">Home</span></a>
                </li>

                {/* <!-- Divider --> */}
                <hr className="sidebar-divider"/>

                {/* <!-- Heading --> */}
                <div className="sidebar-heading">
                    M2M
                </div>

                {profilesLink}

                {eSimsLink}

                {coldStorageLink}

                {campaignLink}

                {/* <!-- Divider --> */}
                <hr className="sidebar-divider"/>

                {/* <!-- Heading --> */}
                <div className="sidebar-heading">
                    Consumer
                </div>

                {consumerLink}

                {qrCampaignLink}
                {/* <!-- Divider --> */}
                <hr className="sidebar-divider" />

                {/* <!-- Heading -->*/}
                <div className="sidebar-heading">
                    eSIM IoT
                </div>

                {iotRSPLink}
                {iotRSPProfilesLink}

                {/* <!-- Divider --> */}
                <hr className="sidebar-divider d-none d-md-block"/>

                {/* <!-- Sidebar Toggler (Sidebar) -->
        <div className="text-center d-none d-md-inline">
          <button onClick={() => { clickMenuOpen() }} className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
        */}


                {/* <!-- Sidebar Message --> */}
                <NewsSideCard toggle={toggled}/>
                {/* <!-- Divider --> */}
                <hr className="sidebar-divider"/>

                {/* <!-- Heading --> */}
                <div className="sidebar-heading">
                    Resources
                </div>

                {/* <!-- Nav Item - API Page --> */}
                <li className={active === "api" ? 'nav-item active' : 'nav-item'}>
                    <a className="nav-link" href="/api">
                        <i className="bi bi-file-binary"></i>
                        <span data-cy="api-documentation-href">API Documentation</span></a>
                </li>

                <li className="nav-item">
                    <a className="nav-link" href={process.env.REACT_APP_WEB_DOCS_BASE_URL} target="_blank"
                       rel="noopener noreferrer">
                        <i className="bi bi-book"></i><span data-cy="user-guide-href">User Guide</span>
                    </a>
                </li>
                <hr className="sidebar-divider"/>
            </ul>)
    }
}

export default withOktaAuth(Sidebar);
