import React, { Component } from "react";
import { OverlayTrigger, Button, Offcanvas, Tooltip } from 'react-bootstrap';
import "./offcanvas.css";
import ICCIDListService from "../../../../services/ICCIDListService";

class OffcanvasOperationButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            value: "",
            validity: 60,
            activationMessage: null
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeVP = this.onChangeVP.bind(this);
    }

    renderOpenButton() {
        if (this.props.isButtonDisabled) {
            return (
                <Button className="btn btn-circle rps-action-btn btn-primary" onClick={() => this.handleShow()} disabled><i className={`bi ${this.props.icon}`} /></Button>
            );
        } else {
            return (
                <OverlayTrigger placement={this.props.placement} overlay={<Tooltip id={`tooltip-${this.props.placement}`}>{this.props.title}</Tooltip>}>
                    <Button className="btn btn-circle rps-action-btn btn-primary"
                        data-tid={this.props.title}
                        onClick={() => this.handleShow()}>
                        <i className={`bi ${this.props.icon}`} />
                    </Button>
                </OverlayTrigger>
            );
        }
    }

    renderActionButton(iccids) {
        if (this.props.operationType === "download-profile") {
            return (<Button className="btn rps-action-btn btn-primary" id="sendButton" onClick={() => { this.sendAndClose(); }} >Send</Button>);
        }
        if (this.props.operationType === "delete-profile") {
            return (<Button className="btn rps-action-btn btn-primary" id="sendButton" onClick={() => { this.sendAndClose(); }} >Send</Button>);
            
        }
        if (this.props.operationType === "profile-info-list") {
            return (<Button className="btn rps-action-btn btn-primary" id="sendButton" onClick={() => { this.sendAndClose(); }} >Send</Button>);
            
        }

        // need to disable the button if no items are enabled in the <select>
        let button = (<Button className="btn rps-action-btn btn-primary" id="sendButton" onClick={() => { this.sendAndClose(); }} disabled>Send</Button>);
        for (let index in iccids) {
            let iccid = iccids[index];
            if (iccid.disabled === "") {
                button = (<Button className="btn rps-action-btn btn-primary" id="sendButton" onClick={() => { this.sendAndClose(); }} >Send</Button>);
            }
        }

        return button;
    }

    onChange(e) {
        this.setState({ value: e.target.value });
    }

    onChangeVP(e) {
        this.setState({ validity: e.target.value });
    }

    renderForm(iccids) {
        let collectDataElements = "";
        if (this.props.operationType === "download-profile") {
            collectDataElements = (
                <div>
                    <div>
                        <label htmlFor="contactDefaultDp-profile">Contact Default SM-DP+:</label>
                        <input type="checkbox" id="contactDefaultDp" name="contactDefaultDp" />
                    </div>
                    <p>
                        <span>Activation Code: </span>
                        <input id="activationcode" type="text" data-tid="activationcode" value={this.state.value} onChange={this.onChange} />
                    </p>
                    <p id="activationCodeMessage" style={{color: "red"}}>{this.state.activationMessage}</p>
                </div>
            );
        } else if(this.props.operationType === "enable-profile"){
            collectDataElements = (
                <div>
                    <p>
                        <span>ICCID:</span>
                        <select name="selected-iccid" id="selected-iccid" data-tid="iccidDropdown" defaultValue="-1" onChange={e => this.doNothing()}>
                            <option value="-1" disabled> -- select an iccid -- </option>
                            {
                                iccids.map((option, index) => (
                                    <option key={index} value={option.iccid} disabled={option.disabled} >{option.iccid}</option>
                                ))
                            }
                        </select>
                    </p>
                </div>
            );
        } else if(this.props.operationType === "delete-profile"){
            collectDataElements = (
                <div>
                    <p>
                        <span>ICCID:</span>
                        <select name="selected-iccid" id="selected-iccid" data-tid="iccidDropdown" defaultValue="-1" onChange={e => this.doNothing()}>
                            <option value="-1" disabled> -- select an iccid -- </option>
                            {
                                iccids.map((option, index) => (
                                    <option key={index} value={option.iccid} disabled={option.disabled}>{option.iccid}</option>
                                ))
                            }
                        </select>
                    </p>
                </div>
            );
        }else if(this.props.operationType === "profile-info-list"){
            <></>
        }else {
            collectDataElements = (
                <div>
                    <span>Placeholder</span>
                </div>
            );
        }
        return collectDataElements;
    }

    sendAndClose = () => {
        if(this.props.operationType === "download-profile"){
            if(this.state.value && !this.state.value.match(/^(1\$)(.*)\$([A-Z0-9-]+)$/)){
                this.setState({"activationMessage": "Invalid Activation Code."})
            } else if(this.state.value && document.getElementById("contactDefaultDp").checked){
                this.setState({"activationMessage": "Only ContactDefaultDP or an Activation Code should be provided."})
            } else{
                this.props.onClick();
                this.handleClose();
            }
        }else {
            this.props.onClick();
            this.handleClose();
        }

    };

    handleClose = () => {
        this.setState({"activationMessage": null})
        this.setState({ show: false, value: "" });
    };

    handleShow = () => {
        this.setState({ show: true });
    };

    doNothing = () => {
        // have to have an onChange fucntion for <select>, so whats why this does nothing
    };

    render() {
        let iccids = [];
        if (this.props.operationType === "enable-profile") {
            iccids = ICCIDListService.createEnabledICCIDList(this.props?.profiles?.profiles);
        }
        if(this.props.operationType === "delete-profile"){
            iccids = ICCIDListService.createDeleteICCIDList(this.props?.profiles?.profiles);
        }

        // dynamically create elements based on context
        let triggerButton = this.renderOpenButton();
        let button = this.renderActionButton(iccids);
        let collectDataElements = this.renderForm(iccids);

        return (
            <>
                {triggerButton}
                <Offcanvas id={this.props.operationType} show={this.state.show} onHide={e => this.handleClose()} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><i className={`bi ${this.props.icon}`} /> {this.props.title}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <p>{this.props.message}</p>
                        {collectDataElements}
                        {button}
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
}

export default OffcanvasOperationButton;