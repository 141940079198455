import React, { Component } from 'react';
import QueryString from 'query-string';

import './iotrsp.css'

//Navigation
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import Footer from '../../components/Footer';
import { withOktaAuth } from '@okta/okta-react';

import IoTProfilesCard from '../../components/IoTProfiles/list-view/IoTProfilesCard';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    
    // redirect to 404 if user doesn't have campaign role
    const claims = this.props.authState.accessToken.claims;
    if(claims){
      const claimKeys = Object.keys(claims);
      const roleKey = claimKeys?.find(key => /roles_/.test(key));
      const roles = claims[roleKey];
      const IoTRole = roles?.find(value => /iot_rsp/.test(value));
      if(!IoTRole){
        window.location.href = "/404";
      }
    }
  }

  render() {
    let params = QueryString.parse(this.props.location.search)

    var size = params.size;
    if (size === undefined) size = 10;


    var page = params.page;
    if (page === undefined) page = 0;

    var search = params.search;
  
    return (
      <div>
        {/* <!-- Page Wrapper --> */}
        <div id="wrapper">
          <Sidebar active="iotprofiles"/>

          {/* <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">

            {/* <!-- Main Content --> */}
            <div id="content">
              <Topbar icon="bi-cpu" title="IoT Profiles"/>

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">
                {<IoTProfilesCard size={size} page={page} search={search}/>}
              </div>
              {/* <!-- /.container-fluid --> */}

            </div>
            {/* <!-- End of Main Content --> */}

            <Footer />
          </div>
          {/* <!-- End of Content Wrapper --> */}

        </div>
        {/* <!-- End of Page Wrapper --> */}
      </div>
    )
  }
}

export default withOktaAuth(Dashboard);
