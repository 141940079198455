import React, { Component } from "react";
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { withOktaAuth } from '@okta/okta-react';

import "./advance-support.css"
import CallbackPane from '../callback/CallbackPane'
import InFlightPane from '../in-flight/InFlightPane'
import ReactDOM from 'react-dom'
import AuditTrailCard from "../audit-trail/AuditTrailCard";
import QueryString from "query-string";

class AdvanceSupport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eid: this.props.eid,
            anchor: this.checkedAnchor(this.props.anchor, this.props.eid),
            oktaAuth: this.props.oktaAuth,
        }
        this.auditTrailRendered = null
        this.callBackRendered = null
        this.inFlightRendered = null

        // reference needed to be able to call function in child components - refresh
        this.callbackPane = React.createRef();
        this.inFlightPane = React.createRef();
        this.eisAuditTrailCard = React.createRef();
    }

    checkedAnchor(anchor, eid) {
        if ((anchor !== "auditTrails") &&
            (anchor !== "callback") &&
            (anchor !== "inflight")
        ) {
            anchor = "auditTrails";
            window.history.replaceState(null, '', 'esims?eid=' + eid + "&advance_support=" + anchor);
        }
        return anchor;
    }
    replaceWindowHistory(anchor, limit) {
        let url = 'esims?eid=' + this.state.eid + "&advance_support=" + anchor;
        if(limit) {
            url += '&limit=' + limit;
        }
        window.history.replaceState(null, '', url);
    }

    updateComponent(anchor) {
        switch (anchor) {
            case "auditTrails":
                if(this.auditTrailRendered === true) {
                    this.eisAuditTrailCard.current.retrieveAuditEIS();
                    this.replaceWindowHistory(anchor, this.eisAuditTrailCard.current.retrieveLimit());
                } else {
                    let params = QueryString.parse(this.props.location.search)
                    let limit = params.limit;
                    if (limit === undefined) limit = 10;
                    if(isNaN(limit)) limit = 10;
                    if (limit > 50) limit = 50;
                    if (limit <= 10) {
                        limit = 10;
                    } else if (limit <= 20)  {
                        limit = 20;
                    } else {
                        limit = 50;
                    }
                    ReactDOM.render(<AuditTrailCard ref={this.eisAuditTrailCard} eid={this.state.eid} oktaAuth={this.state.oktaAuth} limit={limit} anchor={anchor}/>,
                        document.getElementById("auditTrailsTabPane"));
                    this.replaceWindowHistory(anchor, limit);
                    this.auditTrailRendered = true;
                }
                break;

            case "callback":
                if(this.callBackRendered === true){
                    this.callbackPane.current.retrieveCallbacks();
                    this.replaceWindowHistory(anchor);
                } else {
                    ReactDOM.render(<CallbackPane ref={this.callbackPane} eid={this.state.eid} oktaAuth={this.state.oktaAuth} />,
                    document.getElementById("callBackTabPane"))
                    this.replaceWindowHistory(anchor);
                    this.callBackRendered = true;
                }
                break;

            case "inflight":
                if(this.inFlightRendered === true){
                    this.inFlightPane.current.retrieveInflights();
                    this.replaceWindowHistory(anchor);
                } else {
                    ReactDOM.render(<InFlightPane ref={this.inFlightPane} eid={this.state.eid} oktaAuth={this.state.oktaAuth} />,
                    document.getElementById("inFlightTabPane"))
                    this.replaceWindowHistory(anchor);
                    this.inFlightRendered = true;
                }
                break;

            default:
                throw new Error("The anchor received is not supported.")
        }
    }


    createNavLink(eventKey, icon, title, disabled) {
        return (
            <Nav.Link eventKey={eventKey} disabled={disabled}
                onClick={() => this.updateComponent(eventKey)}>
                <i className={"bi " + icon} data-tid={title} id={"nav-link-" + eventKey}></i> {title}
            </Nav.Link>
        )
    }
    
    handleInitialRender(pageRenderAnchor) {
        let auditTrailsAnchor = null;
        let inFlightAnchor = null;
        let callBackAnchor = null;

        if (pageRenderAnchor === "auditTrails") {
            let params = QueryString.parse(this.props.location.search)

            let limit = params.limit;
            if (limit === undefined) limit = 10;
            if(isNaN(limit)) limit = 10;
            if (limit > 50) limit = 50;
            if (limit <= 10) {
                limit = 10;
            } else if (limit <= 20)  {
                limit = 20;
            } else {
                limit = 50;
            }
            auditTrailsAnchor = <AuditTrailCard ref={this.eisAuditTrailCard} eid={this.state.eid} oktaAuth={this.state.oktaAuth} limit={limit} anchor={pageRenderAnchor}/>;
            this.replaceWindowHistory(pageRenderAnchor, limit);
            this.auditTrailRendered = true
        }
        else if (pageRenderAnchor === "callback") {
            callBackAnchor = <CallbackPane ref={this.callbackPane} eid={this.state.eid} oktaAuth={this.state.oktaAuth} />;
            this.callBackRendered = true
        }
        else if (pageRenderAnchor === "inflight") {
            inFlightAnchor = <InFlightPane ref={this.inFlightPane} eid={this.state.eid} oktaAuth={this.state.oktaAuth} />;
            this.inFlightRendered = true
        }
        return {auditTrailsAnchor, inFlightAnchor, callBackAnchor}
    
    }

    render() {
        var pageRenderAnchor = this.state.anchor

        let { auditTrailsAnchor, inFlightAnchor, callBackAnchor } = this.handleInitialRender(pageRenderAnchor);
        return (
            <div className="col-lg-12 d-grid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-danger">
                            <i className="bi bi bi-headset"></i> Advanced Support</h6>
                    </div>
                    <div className="card-body">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.anchor}>
                            <Row>
                                <Col className="border-right-secondary" sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        {this.createNavLink("auditTrails", "bi-list-ol", "Audit Trail", false)}
                                        {this.createNavLink("callback", "bi-check-circle", "Acknowledged Operations", false)}
                                        {this.createNavLink("inflight", "bi-truck", "In-Flight Operations", false)}
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content >

                                    <Tab.Pane eventKey="auditTrails">
                                        <div id="auditTrailsTabPane">{auditTrailsAnchor}</div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="inflight">
                                        <div id="inFlightTabPane">{inFlightAnchor}</div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="callback">
                                    <div id="callBackTabPane">{callBackAnchor}</div>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
            </div >
        )
    }

}

export default withOktaAuth(AdvanceSupport);
