export default class ICCIDListService {

  static createEnabledICCIDList = (profiles) => {
    let iccids = [];
    let option = {};

    if (profiles !== null) {
      if (profiles?.profileInfo) {
        for (let i = 0; i < profiles?.profileInfo?.length; i++) {
          option = {};
          let profile = profiles?.profileInfo[i];

          option.iccid = profile.iccid;
          if (profile.state === "ENABLED") {
            option.iccid = option.iccid + " - Already Enabled";
            option.disabled = "disabled";
          } else {
            option.disabled = "";
          }
          iccids.push(option);
        }
      } else {
        /* This block is used by the IOT RSP Devices */
        for (let i = 0; i < profiles?.length; i++) {
          option = {};
          let profile = profiles[i];

          // var option = {};
          option.iccid = profile.iccid;
          if (profile.state === "ENABLED") {
            option.iccid = option.iccid + " - Already Enabled";
            option.disabled = "disabled";
          } else {
            option.disabled = "";
          }
          iccids.push(option);
        }
      }
    }

    return iccids;
  };

  static createSetFallbackAttributeICCIDList = (profiles) => {
    let iccids = [];

    if (profiles !== null) {
      for (let i = 0; i < profiles?.profileInfo?.length; i++) {
        let profile = profiles.profileInfo[i];

        var option = {};
        option.iccid = profile.iccid;
        if (profile.fallbackAttribute === true) {
          option.iccid = option.iccid + " - Already set";
          option.disabled = "disabled";
        } else {
          option.disabled = "";
        }

        iccids.push(option);
      }
    }

    return iccids;
  };

  static createDisableICCIDList = (profiles) => {
    let iccids = [];

    if (profiles !== null) {
      for (let i = 0; i < profiles?.profileInfo?.length; i++) {
        let profile = profiles.profileInfo[i];

        var option = {};
        option.iccid = profile.iccid;

        // check if profile has fallback attribute set to true - if so it can not be disabled
        if (profile.fallbackAttribute === true) {
          option.iccid = option.iccid + " - Fallback Set";
          option.disabled = "disabled";
        } else {

          // check if profile is already 'DISABLED'
          if (profile.state === "DISABLED") {
            option.iccid = option.iccid + " - Already Disabled";
            option.disabled = "disabled";
          } else {
            option.disabled = "";
          }

          // check POL2 rules - IS PROFILE-DISBLE-ALLOWED
          let isProfileDisableNotAllowedRule = this.isProfileDisableNotAllowedRule(profile);
          if (isProfileDisableNotAllowedRule) {
            if (!option.iccid.includes("Already Disabled")) {
              option.iccid = option.iccid + " - Disable not allowed";
              option.disabled = "disabled";
            }
          }
        }


        iccids.push(option);
      }
    }

    return iccids;
  };

  static createDeleteICCIDList = (profiles) => {
    let iccids = [];
    var option = {};

    if (profiles !== null) {
      if (profiles?.profileInfo) {
        for (let i = 0; i < profiles?.profileInfo?.length; i++) {
          let profile = profiles.profileInfo[i];

          option = {};
          option.iccid = profile.iccid;
          option.disabled = "";
          // check if profile has fallback attribute set to true - if so it can not be deleted
          if (profile.fallbackAttribute === true) {
            option.iccid = option.iccid + " - Fallback Set";
            option.disabled = "disabled";
          } else {
            // check POL2 rules - IS PROFILE-DELETE-ALLOWED
            let isProfileDeleteNotAllowedRule = this.isProfileDeleteNotAllowedRule(profile);
            if (isProfileDeleteNotAllowedRule) {
              option.iccid = option.iccid + " - Delete not allowed";
              option.disabled = "disabled";
            }
          }

          iccids.push(option);
        }
      } else {
        /* This block is used by the IOT RSP Devices */
        for (let i = 0; i < profiles?.length; i++) {
          let profile = profiles[i];
          option = {};
          option.iccid = profile.iccid;
          if (profile.state === "ENABLED") {
            option.iccid = option.iccid + " - Enabled";
            option.disabled = "disabled";
          } else {
            option.disabled = "";
          }
          iccids.push(option);
        }
      }
    }

    return iccids;
  };

  /**
   * Function to check if a Profile has the POL2 Rule 
   *  {Subject=”PROFILE”, action=”DELETE”, qualification=”Not allowed”} is set
   */
  static isProfileDeleteNotAllowedRule = (profile) => {
    let rules = profile.pol2Rules;
    if (rules.length === 0) {
      return false;
    } else {
      for (let i = 0; i < rules.length; i++) {
        let rule = rules[i];
        if ((rule.action === "DELETE") && (rule.qualification === "NOT_ALLOWED")) {
          return true;
        }
      }
      return false;
    }
  };

  /**
   * Function to check if a Profile has the POL2 Rule 
   *  {Subject=”PROFILE”, action=”DISABLE”, qualification=”Not allowed”} is set
   */
  static isProfileDisableNotAllowedRule = (profile) => {
    let rules = profile.pol2Rules;
    if (rules.length === 0) {
      return false;
    } else {
      for (let i = 0; i < rules.length; i++) {
        let rule = rules[i];
        if ((rule.action === "DISABLE") && (rule.qualification === "NOT_ALLOWED")) {
          return true;
        }
      }
      return false;
    }
  };

  /**
  * Function to check if a Profile has the POL2 Rule 
  *  {Subject=”PROFILE”, action=”DISABLE”, qualification=”Auto-delete”} is set
  */
  static isProfileDisableAutoDeleteRule = (profile) => {
    let rules = profile.pol2Rules;
    if (rules.length === 0) {
      return false;
    } else {
      for (let i = 0; i < rules.length; i++) {
        let rule = rules[i];
        if ((rule.action === "DISABLE") && (rule.qualification === "AUTO_DELETE")) {
          return true;
        }
      }
      return false;
    }
  };
}