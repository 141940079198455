import http from "../api/http-iot-consumer-sandbox";
// import ReactDOM from 'react-dom';
// import AlertOperationRequest from "../components/IoTDevices/single-view/rsp-operations/AlertOperationRequest";

const getAll = (config) => {
  return http.get("/iot/consumer/inventory/profiles", config);
};

const getIccid = (iccid, config) => {
  return http.get("/iot/consumer/inventory/search/" + iccid, config);
};

const orderProfile = (iccid, eid, config) => {
  console.log("EIDINSERVICE", document.getElementById("selected-eid").value)
  const data = {
    iccid: iccid,
    eid: eid
  }
  return http.post("/iot/consumer/orderProfile", data, config);
};

const cancelOrder = (iccid, config) => {
  const data = {
    iccid: iccid
  }
  return http.post("/iot/consumer/cancelOrder", data, config);
};

function getRequestParams(page, size, search) {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (size) {
    params["size"] = size;
  }

  if (search) {
    params["searchFilter"] = search;
  }

  return params;
};

const functions = {
  getAll,
  getIccid,
  orderProfile,
  cancelOrder,
  getRequestParams,
};

export default functions;
