import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import ReactPaginate from "react-paginate";
import { Alert } from 'react-bootstrap';

import IoTDeviceService from "../../../services/IoTDeviceService.js";

import Search from "../../common/search/Search.js";
import IoTeUICCTable from "./IoteUICCTable.js";
import { withOktaAuth } from "@okta/okta-react";
import HttpUtil from "../../../api/http-util.js";
import AlertOperationRequest from "../single-view/rsp-operations/AlertOperationRequest.js";
import ReactDOM from 'react-dom';
import ShowItems from "../../common/table/ShowItems.js";

class IoTeUICCsCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: null,
			page: this.props.page,
			size: this.props.size,
			totalPages: Number(this.props.page) + 1,
			search: this.props.search,
			oktaAuth: this.props.oktaAuth,
			totalItems: 0,
			firstRecord: 0,
			lastRecord: 0,
			disabled: null,
		};
	}

	handleAPIResponse = (response) => {
		if (response.data.content.length === 0) {
			this.setState({ totalPages: 0 }); //disable pagination as only one item should be returned
			this.setState({ disabled: "disabled" });
			this.setState({ firstRecord: 0 });
			this.setState({ lastRecord: 0 });
			this.setState({ totalItems: 0 });
			this.setState({ disabled: "disabled" });
			let content = [];
			this.setState({ content: content });
		} else {
			var page = response.data.page;
			this.setState({ totalPages: page.totalPages });
			this.setState({ content: response.data.content });
			this.setState({ totalItems: response.data.page.totalElements });
			this.calculateFirstRecord();
			this.calculateLastRecord();
		}
	};

	calculateFirstRecord() {
		this.setState({ firstRecord: this.state.page * this.state.size + 1 });
	}

	calculateLastRecord() {
		var recordEnd =
			this.state.page * parseInt(this.state.size) +
			parseInt(this.state.size);
		if (recordEnd > this.state.totalItems) {
			this.setState({ lastRecord: this.state.totalItems });
		} else {
			this.setState({ lastRecord: recordEnd });
		}
	}

	retrieveEis = () => {
		if (this.state.size < 10) {
			this.setState({ size: 10 });
			this.handlePageChange({ 'selected': parseInt(this.state.page) });
		} else if (this.state.size > 10 && this.state.size < 20) {
			this.setState({ size: 20 });
			this.handlePageChange({ 'selected': parseInt(this.state.page) });
		} else if ((this.state.size > 20 && this.state.size < 50) || this.state.size > 50) {
			this.setState({ size: 50 });
			this.handlePageChange({ 'selected': parseInt(this.state.page) });
		}

		const params = IoTDeviceService.getRequestParams(
			this.state.page,
			this.state.size,
			this.state.search
		);
		const config = HttpUtil.getHttpConfig(
			params,
			this.state.oktaAuth.getAccessToken()
		);

		if (this.state.search !== "" && this.state.search !== undefined) {
			IoTDeviceService.getEidListView(this.state.search, config)
				.then((response) => this.handleAPIResponse(response))
				.catch((e) => {
					console.log(`Error occurred in EisSearch: ${e}`);
					this.setState({ totalPages: 0 });
					this.setState({ firstRecord: 0 });
					this.setState({ lastRecord: 0 });
					this.setState({ totalItems: 0 });
					this.setState({ content: [] });
				});
		} else {
			IoTDeviceService.getAll(config)
				.then((response) => this.handleAPIResponse(response))
				.catch((e) => {
					console.log(`Error occurred in EisService getAll: ${e}`);
					this.setState({ totalPages: 0 });
					this.setState({ firstRecord: 0 });
					this.setState({ lastRecord: 0 });
					this.setState({ totalItems: 0 });
					this.setState({ content: [] });
				});
		}
	};

	retrieveEisByIccid = () => {
		const params = IoTDeviceService.getRequestParams(
			this.state.page,
			this.state.size,
			this.state.search
		);
		const config = HttpUtil.getHttpConfig(
			params,
			this.state.oktaAuth.getAccessToken()
		);
		if (this.state.search !== "" && this.state.search !== undefined) {
			IoTDeviceService.getEidByIccidListView(this.state.search, config)
				.then((response) => this.handleAPIResponse(response))
				.catch((e) => {
					console.log(`Error occurred in getEidByIccid: ${e}`);
					this.setState({ totalPages: 0 });
					this.setState({ firstRecord: 0 });
					this.setState({ lastRecord: 0 });
					this.setState({ totalItems: 0 });
					this.setState({ content: [] });
				});
		} else {
			IoTDeviceService.getAll(config)
				.then((response) => this.handleAPIResponse(response))
				.catch((e) => {
					console.log(`Error occurred in EisService getAll: ${e}`);
					this.setState({ totalPages: 0 });
					this.setState({ firstRecord: 0 });
					this.setState({ lastRecord: 0 });
					this.setState({ totalItems: 0 });
					this.setState({ content: [] });
				});
		}
	};

	componentDidMount() {
		if (this.state.page > 0) {
			this.handlePageChange({ 'selected': parseInt(this.state.page) });
		} else if (this?.props?.search?.length >= 18 && this?.state?.search?.length <= 22) {
			this.retrieveEisByIccid();
		} else {
			this.retrieveEis();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		var newSizeParam = "size=" + this.state.size;
		var newPageParam = "&page=" + this.state.page;
		var newSearchParam = "";

		var includeSearch = false;
		if (this.state.search !== undefined && this.state.search !== "") {
			newSearchParam = "&search=" + this.state.search;
			includeSearch = true;
		}

		if (
			prevState.size !== this.state.size ||
			prevState.page !== this.state.page ||
			prevState.search !== this.state.search
		) {
			// Verify that search string is only numeric characters
			if (includeSearch && Boolean(this.state.search.match(/^\d+[F]?$/)) === false && this.state.search !== "") {
				this.showAlert("Danger", "Invalid Search", `An EID or ICCID must contain only numeric characters.`);
				window.history.replaceState(
					null,
					"",
					"ioteuiccs?" + newSizeParam + newPageParam
				);
			} else if (includeSearch && this.state.search.length >= 18 && this.state.search.length <= 22) {
				this.hideAlert();
				this.retrieveEisByIccid();
				window.history.replaceState(
					null,
					"",
					"ioteuiccs?" + newSizeParam + newPageParam + newSearchParam
				);
			}
			else {
				// This is for initial render and when you clear searchbar
				this.hideAlert();
				this.retrieveEis();
				window.history.replaceState(
					null,
					"",
					"ioteuiccs?" + newSizeParam + newPageParam
				);
			}
		}
	}

	handleSizeChange = (event) => {
		this.setState({ size: event.target.value, page: 0, search: undefined });
		this.hideAlert();
		document.getElementById("searchBar").value = '';
	};

	handlePageChange = (event) => {
		this.setState({ page: event.selected, search: undefined });
		this.hideAlert();
		document.getElementById("searchBar").value = '';
	};

	handleSearchBoxClick = () => {
		var searchBar = document.getElementById("searchBar");
		this.setState({ search: searchBar.value });
		this.setState({ page: 0 });
	};

	handleSearchBoxChange = (event) => {
		// only send the search when the 'enter' button is pressed to reduce API calls
		if (event.key === "Enter") {
			this.setState({ search: event.target.value });
			this.setState({ page: 0 });
		}
	};

	showAlert = (variant, heading, message) => {
		ReactDOM.render(
			<AlertOperationRequest variant={variant} heading={heading} message={message} close={this.hideAlert} />,
			document.getElementById('alert-form')
		);
		// setTimeout(() => this.hideAlert(), 4000);
	};

	hideAlert = () => {
		ReactDOM.render("", document.getElementById('alert-form'));
	};

	render() {
		return (
			<div className="row">
				<div className="col-lg-12 d-grid">
					<div>
						<Alert variant={"warning"} data-tid={"feedbackPopup"} style={{ height: "50px" }}>
							<p>If you have any questions or have any feedback to share regarding eSim IoT features with us, please reach us at
								<a href=" " style={{ "pointerEvents": "none", "cursor": "default" }}> feedback-pulse@kigen.com</a></p>
						</Alert>
					</div>
					<div className="card shadow mb-4">
						<div className="card-header py-3">
							<h6 className="m-0 font-weight-bold text-primary">
								<i className="bi bi-cpu"></i> My IoT eUICCs
							</h6>
						</div>
						<div className="card-body">
							<div className="search-responsive">
								<div id="alert-form" className="col-lg-12 d-grid" />
								<div className="row">
									<Search
										value={this.state.search}
										placeholder="Search by EID or ICCID..."
										handleSearchBoxChange={
											this.handleSearchBoxChange
										}
										handleSearchBoxClick={
											this.handleSearchBoxClick
										}
									/>
									{/* <TableButtons /> */}
								</div>
							</div>
							<div className="table-responsive">
								<IoTeUICCTable content={this.state.content} />
							</div>
							<hr />
							<div className="table-bottom-bar">
								<div className="row">
									<ShowItems
										size={this.state.size}
										handleSizeChange={this.handleSizeChange}
									/>
									<div
										className={
											"col-sm-12 col-md-6 react-paginate" +
											this.state.disabled
										}
									><div>
											<ReactPaginate
												previousLabel={"Previous"}
												nextLabel={"Next"}
												breakLabel={"..."}
												pageCount={this.state.totalPages}
												marginPagesDisplayed={1}
												pageRangeDisplayed={3}
												onPageChange={this.handlePageChange}
												renderOnZeroPageCount={null}
												containerClassName={"pagination"}
												pageClassName={"page-item"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
												previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
												forcePage={Number(this.state.page)}
											/>
										</div>
									</div>
									<div className="record-count">
										Showing items {this.state.firstRecord} -{" "}
										{this.state.lastRecord} of{" "}
										{this.state.totalItems}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withOktaAuth(withRouter(IoTeUICCsCard));
