import React, { Component } from "react";
import { Button, Offcanvas } from 'react-bootstrap';
import "./offcanvas.css";
import IotDeviceService from '../../../../services/IoTDeviceService';

// import HttpUtil from '../../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';

class ConfigureImmediateEnable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            enabledStatus: 'Disabled',
            checkedStatus: false,
            initialCheckedStatus : false,
            oktaAuth: this.props.oktaAuth,
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        //Caution:never use this.setState directly in componentDidUpdate.
        if (prevProps.data !== this.props.data) {
            this.updateContent(this.props.data)
        }
    }

    updateContent(data) {
        if (data?.immediateEnableConfiguration?.immediateEnableFlag === true) {
            this.setState({ enabledStatus: "Enabled", checkedStatus: true });
            this.setState({ initialCheckedStatus: true });
        }
    }

    configureImmediateEnable = () => {
        IotDeviceService.configureImmediateEnable(this.props.data.eid, this.state.checkedStatus, this.props.oktaAuth.getAccessToken())
      };

    handleShow = () => {
        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false , checkedStatus: false});
    };

    onChange() {
        if (this.state.checkedStatus === true) {
            this.setState({ checkedStatus: false });
        } else
            if (this.state.checkedStatus === false) {
                this.setState({ checkedStatus: true });
            }
    }

    renderForm = () => {
        return (
            <div>

                <div className="custom-control custom-switch" style={{"paddingBottom":"10px"}}>
                    <input data-tid="disable-enable-input" type="checkbox" className="custom-control-input" id="configureImmediateEnableCheckbox" checked={this.state.checkedStatus} onChange={this.onChange}></input>
                    <label className="custom-control-label" htmlFor="configureImmediateEnableCheckbox">Disable / Enable</label>
                </div>
            </div>);
    };

    sendAndClose = () => {
        this.configureImmediateEnable();
        this.handleClose();
    };

    render() {
        const sendButton = <Button data-tid="send-button" className="btn rps-action-btn btn-primary" id="sendButton" onClick={() => { this.sendAndClose(); }} disabled={this.state.initialCheckedStatus === this.state.checkedStatus}>Send</Button>;
        return (
            <>
                <div className={'click-row col-md-12 mb-4 click-card col-xl-' + this.props.colWidth} >
                    <div className={'card shadow py-2 border-left-' + this.props.color + " " + this.props.fill}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className={'text-xs font-weight-bold text-uppercase mb-1 text-' + this.props.color}>{this.props.name}</div>
                                    <div>
                                        <div data-tid={this.props.name} className={'h6 mb-0 text-gray-800 '}>{this.state.enabledStatus}
                                            <Button data-tid="enable-config-button" className="btn btn-circle rps-action-btn btn-primary float-right" onClick={() => this.handleShow()}><i className={"bi bi-pencil-square"} /></Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className={'bi fa-2x'}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Offcanvas id={this.props.operationType} show={this.state.show} onHide={e => this.handleClose()} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title> <i className={`bi bi-pencil-square`} />{" Configure Immediate Enable"}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {this.renderForm()}
                        {sendButton}
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
}
export default withOktaAuth(ConfigureImmediateEnable);