import http from "../api/http-common";

const getAll = (config) => {
    let request = http.get("/rsp/inventory/eis/", config );
    console.log("request", request)
    return request
};

const search = (config) => {
  return http.get("/rsp/inventory/eis/search/", config );
};

const getEid = (eid, config) => {
  return http.get("/rsp/inventory/eis/" + eid + "/", config);
}; 

const getEidAudit = (eid, limit, config) => {
  return http.get("/rsp/inventory/eis/" + eid + "/audittrail/limit/-" + limit, config);
};

const getEidByIccid = (iccid, config) => {
  return http.get("/rsp/inventory/eis/find/iccid/" + iccid, config);
};

function transformResponse(content){
  var data = [];

  for (var item of content) {
    var eis = [];
    eis.eid = item._id;
    eis.group = "WAITING ON FEATURE";
    eis.numberOfProfiles = item.profileInfo.length;

    for (var profile of item.profileInfo) {
      if ((profile.state === "ENABLED") || (profile.state === "CREATED")){
          eis.enabled = [];
          eis.enabled.iccid = profile.iccid;
          eis.enabled.profileType = profile.profileType;
          eis.enabled.group = "WAITING ON FEATURE"; //https://kigen.atlassian.net/browse/M2M-358
          var isFallbackSet = "No";
          if (profile.fallbackAttribute === true){
            isFallbackSet = "Yes";
          }
          eis.enabled.fallbackAttribute = isFallbackSet;
      }
    }
    data.push(eis);
  }

  return data;
}

function reverseOrder(content){
  return content.sort().reverse();
}

function getRequestParams(page, size, search){
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (size) {
    params["size"] = size;
  }

  if (search) {
    params["searchFilter"] = search;
  }

  return params;
}

const functions = {
  getAll,
  search,
  getEid,
  getEidAudit,
  getEidByIccid,
  getRequestParams,
  transformResponse,
  reverseOrder
};

export default functions;
