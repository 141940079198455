import React, { Component } from "react";
import './iotdevice-single.css';

import IoTeUICCService from '../../../services/IoTDeviceService';

import HighlightCardValue from '../../HighlightCardValue'
import ConfigureImmediateEnable from './ConfigureImmediateEnable'
import IoTeUICCInfoTable from './IoTeUICCInfoTable';
import AdvanceSupport from './advance-support/AdvanceSupport';
import { Alert } from 'react-bootstrap';

import SingleRspOperationsButtons from "./rsp-operations/SingleRspOperationsButtons";

import HttpUtil from '../../../api/http-util';
import { withOktaAuth } from '@okta/okta-react';


class IoTeUICCSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eid: this.props.eid,
      anchor: this.props.anchor,
      eidData: null,
      enabledIccid: '',
      oktaAuth: this.props.oktaAuth
    };
  }

  retrieveEid = () => {
    this.setState({ eidData: null });

    const config = HttpUtil.getAuthHeader(this.state.oktaAuth.getAccessToken());
    IoTeUICCService.getEid(this.state.eid, config)
      .then((response) => {
        var eidData = response.data;
        this.setState({ eidData: eidData });
        this.findEnabledProfileIccid();
      })
      .catch((e) => {
        window.location.href = "/404";
        this.setState({ eidData: [] });

      });
  };

  findEnabledProfileIccid() {
    if (this.state.eidData.profileInfo !== undefined) {
      for (let profile of this.state.eidData.profileInfo) {
        if (profile.state === "ENABLED") {
          this.setState({ enabledIccid: profile.iccid });
        }
      }
    }
  }

  componentDidMount() {
    // load data on first component render
    this.retrieveEid();
  }


  render() {
    return (
      <div>
        {/* <!-- alert banner --> */}
        <div className="row">
          <div id="alert-banner" className="col-lg-12 d-grid" />
        </div>
        <div>
						<Alert variant={"warning"} data-tid={"feedbackPopup"} style={{ height: "50px" }}>
							<p>If you have any questions or have any feedback to share regarding eSim IoT features with us, please reach us at
								<a href=" " style={{ "pointerEvents": "none", "cursor": "default" }}> feedback-pulse@kigen.com</a></p>
						</Alert>
					</div>

        {/* <!-- eis card overview & rsp actions --> */}
        <div className="row">
          <HighlightCardValue colWidth="4" icon="" bold=""
            name="EID" value={this.state.eid} color="warning" />

          <ConfigureImmediateEnable colWidth="4"
            name="Configure Immediate Enable Status" data={this.state.eidData} color="success" />

          <SingleRspOperationsButtons colWidth="4" name="IoT Device Single Operations" color="info"
            eid={this.state.eid} profiles={this.state.eidData} />
        </div>

        {/* <!-- profile information  --> */}
        <div className="row">
          <IoTeUICCInfoTable content={this.state.eidData} onRefresh={this.retrieveEid}/>
        </div>

        {/* <!-- advance support --> */}
        <div className="row">
          <AdvanceSupport eid={this.state.eid} anchor={this.state.anchor} />
        </div>

      </div>
    );
  }
};

export default withOktaAuth(IoTeUICCSingle);
