import React, { Component } from "react";

//import OperationButton from "./OperationButton";
import OffcanvasOperationButton from "./OffcanvasOperationButton";
import IoTRSPService from "../../../../services/IoTDeviceService";

import { withOktaAuth } from '@okta/okta-react';

class SingleRspOperationsButtons extends Component {

    shouldProfileButtonsBeDisabled(){
        let isButtonDisabled = false;

        if (this.props.profiles !== null){
            let numberOfProfiles = this.props?.profiles?.profiles.length;
            if (numberOfProfiles <= 1){
                isButtonDisabled = true
            }
        }
        return isButtonDisabled;
    }

    render(){
        let isButtonDisabled = this.shouldProfileButtonsBeDisabled();

        return (
            <div className={'click-row col-md-12 mb-4 click-card col-xl-' + this.props.colWidth}>
                <div className={'card shadow h-100 py-2 border-left-' + this.props.color}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                            <div className={'text-xs font-weight-bold text-uppercase mb-1 text-' + this.props.color}>{this.props.name}</div>
                            <div className={'h6 mb-0 text-gray-800'}>
                                <div className="action-btns">
                                    
                                    {/* DOWNLOAD PROFILE */}
                                    <OffcanvasOperationButton placement="bottom" title="Download Profile" icon="bi-cloud-arrow-down" isButtonDisabled={false}
                                        profiles={this.props.profiles} operationType="download-profile"
                                        message="Please enter the profile you would like to download on to this EID."
                                        onClick={e =>  IoTRSPService.downloadProfile(this.props.eid, this.props.oktaAuth.getAccessToken()) }
                                        />

                                    <OffcanvasOperationButton placement="bottom" title="Enable Profile" icon="bi-file-check" isButtonDisabled={isButtonDisabled}
                                        profiles={this.props.profiles} operationType="enable-profile"
                                        message="Please select the ICCID that you'd like to Enable on this eUICC."
                                        onClick={e => IoTRSPService.enableProfile(this.props.eid, this.props.oktaAuth.getAccessToken())}
                                        />

                                    <OffcanvasOperationButton placement="bottom" title="Delete Profile" icon="bi-file-excel"
                                        isButtonDisabled={isButtonDisabled}
                                        profiles={this.props.profiles} operationType="delete-profile"
                                        message="Please select the ICCID that you'd like to Delete from this eUICC."
                                        onClick={e => IoTRSPService.deleteProfile(this.props.eid, this.props.oktaAuth.getAccessToken())}
                                        />

                                    <OffcanvasOperationButton placement="bottom" title="Get Profile Info List" icon="bi-bug"
                                        isButtonDisabled={false}
                                        operationType="profile-info-list"
                                        message="Get Profile Info List."
                                        onClick={e => IoTRSPService.getProfileInfoList(this.props.eid, this.props.oktaAuth.getAccessToken())}
                                        />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default withOktaAuth(SingleRspOperationsButtons);
