import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import IoTProfilesService from "../../../services/IoTProfilesService";
import AlertOperationRequest from "./AlertOperationRequest.js";
import HttpUtil from "../../../api/http-util";
import { withOktaAuth } from "@okta/okta-react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class IotProfilesItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oktaAuth: this.props.oktaAuth,
            errorMessage: "",
            eid: ""
        };
    };

    orderProfile = (iccid) => {
        const config = HttpUtil.getHttpConfig(
            {},
            this.state.oktaAuth.getAccessToken()
        );
        if(!this.state.eid){
            this.setState({errorMessage: "EID Value cannot be blank."})
        } else{
        IoTProfilesService.orderProfile(iccid, this.state.eid, config)
            .then((response) => {
                this.showAlert("success", "Profile Ordered Successfully", "Profile Ordered Successfully");
                // refresh window and re-direct
                setTimeout(() => {
                    this.props.history.push(`/iotprofiles?size=10&page=0&search=${iccid}`);
                    window.location.reload();
                }, 1000);
            })
            .catch((error) => {
                console.log("error", error);
                this.showAlert("danger", "Error Ordering Profile", "Failed to Order profile");
            });
        }
    };

    cancelOrder = (iccid) => {
        const config = HttpUtil.getHttpConfig(
            {},
            this.state.oktaAuth.getAccessToken()
        );
        IoTProfilesService.cancelOrder(iccid, config)
            .then((response) => {
                this.showAlert("success", "Order Cancelled Successfully", "Order Cancelled Successfully");

                // refresh window and re-direct
                setTimeout(() => {
                    this.props.history.push(`/iotprofiles?size=10&page=0&search=${iccid}`);
                    window.location.reload();
                }, 1000);
            })
            .catch((error) => {
                console.log("error", error);
                this.showAlert("danger", "Error Cancelling Order", "Error Cancelling Order");
            });
    };

    showAlert = (variant, heading, message) => {
        ReactDOM.render(
            <AlertOperationRequest variant={variant} heading={heading} message={message} close={this.hideAlert} />,
            document.getElementById('alert-form')
        );
        setTimeout(() => this.hideAlert(), 5000);
    };

    hideAlert = () => {
        ReactDOM.render("", document.getElementById('alert-form'));
    };

    renderCancelOrderButton() {
        let cancelButton;

        if (this.props.iccid.state === "RELEASED" || this.props.iccid.state === "ERROR") {
            cancelButton = <OverlayTrigger placement={"bottom"} overlay={<Tooltip id={`tooltip-bottom`}>{"Cancel Order"}</Tooltip>}>
                <button type="button" className="btn btn-primary btn-cold-activate" data-toggle="modal" data-target={'#cancelOrder' + this.props.iccid.iccid}>
                    <i className="bi bi-x-circle" />
                </button>
            </OverlayTrigger>;

        } else {
            cancelButton = <button type="button" className="btn btn-primary btn-cold-activate" data-toggle="modal" data-target={'#cancelOrder'} disabled>
                <i className="bi bi-x-circle" />
            </button>;
        }
        return cancelButton;
    }

    renderCancelOrderForm(iccid) {
        return <div className="modal fade" id={'cancelOrder' + iccid} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Cancel Order</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body fontSizeTable">
                        <div>
                            <p>Are you sure you wish to cancel this existing order?</p>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button data-tid={'cancelOrder-cancel-modalButton-' + iccid} id={'cancelOrderBtn'} type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button data-tid={'cancelOrder-confirm-modalButton-' + iccid} id={'confirmCancelBtn'} type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => this.cancelOrder(this.props.iccid.iccid)}>Confirm</button>
                        
                    </div>
                </div>
            </div>
        </div>;
    }

    renderOrderButton() {
        let orderButton;
        if (this.props.iccid.state === "AVAILABLE") {
            orderButton = <OverlayTrigger placement={"bottom"} overlay={<Tooltip id={`tooltip-bottom`}>{"Order Profile"}</Tooltip>}>
                <button type="button" className="btn btn-primary btn-cold-activate" data-toggle="modal" data-target={'#orderProfile' + this.props.iccid.iccid}>
                    <i className="bi bi-box-arrow-right" />
                </button>
            </OverlayTrigger>;
        } else {
            orderButton = <button type="button" className="btn btn-primary btn-cold-activate" data-toggle="modal" data-target={'#orderProfile'} disabled>
                <i className="bi bi-box-arrow-right" />
            </button>;
        }
        return orderButton;
    }

    renderOrderForm(iccid) {
        return <div className="modal fade" id={'orderProfile' + iccid} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Order Profile</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.resetOrderModal()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body fontSizeTable">
                        <div>
                            <p>Please enter the EID for this order:</p>
                            <p>
                                <span><b>EID</b>: </span>
                                <input
                                    id="selected-eid"
                                    type="text"
                                    data-tid={'selectedEid-' + iccid}
                                    onChange={(e) => this.handleEIDValidation(e.target.value)}
                                    required />
                            </p>
                        </div>
                        <i style={{color:"red"}}>{this.state.errorMessage}</i>

                    </div>
                    <div className="modal-footer">
                        <button data-tid={'cancel-button-' + iccid} id={'cancelOrderBtn'} type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.resetOrderModal()}>Cancel</button>
                        <button data-tid={'confirm-button-' + iccid} id={'confirmOrderBtn'} type="button" className="btn btn-danger" data-dismiss="modal" onClick={(e) => this.orderProfile(iccid)}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>;
    }

    handleEIDValidation(value) {
        this.setState({eid: value})
    }

    resetOrderModal() {
        this.setState({eid: "", errorMessage: ""})
    }

    render() {
        let profileStatusBadge = <span style={{ backgroundColor: this.props.stateColors[this.props.iccid.state].toUpperCase() }} className="badge text-light">{this.props.iccid.state}</span>;
        return <tr>
            <td data-cy="iccid">{this.props.iccid.iccid}</td>
            <td data-cy="mnoId">{this.props.iccid.mnoId}</td>
            <td data-cy="profileType">{this.props.iccid.profileType}</td>
            <td data-cy="state">{profileStatusBadge}</td>

            <td>
                <div className="row">
                    <div data-cy="orderButton">
                        {this.renderOrderButton()}

                        {this.renderOrderForm(this.props.iccid.iccid)}
                    </div>

                    <div data-cy="cancelOrderButton" style={{ paddingLeft: "15px" }}>
                        {this.renderCancelOrderButton()}

                        {/* THIS MIGHT NEED TO BE MOVED */}
                        {this.renderCancelOrderForm(this.props.iccid.iccid)}
                    </div>
                </div>
            </td>
        </tr>;
    }
};

export default withOktaAuth(withRouter(IotProfilesItem));
