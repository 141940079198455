import React, { Component } from "react";
import { Button } from 'react-bootstrap';

class ProfileItem extends Component {

    isProfileEnabled(){
        if (this.props.profile.state === "ENABLED"){
            return <i className="bi bi-caret-right-fill"></i>
        }
        return "";
    }

    renderGroup(){
        return <span className="badge bg-success text-light">WAITING ON FEATURE</span>
    }

    renderPol2Rules(){
        if (this.props.profile.pol2Rules.length === 0){
            return "No rules";
        }else{
            var rules = []
            for (var rule of this.props.profile.pol2Rules){
                var r = (rule.action + ' ' + rule.qualification);
                rules.push(<span key={r} className="badge bg-danger text-light">{r}</span>);
            }
            
            return rules;
        }
    }

    renderProfileState(){
        if (this.props.profile.state === "ENABLED"){
            return <span className="badge bg-success text-light"><i className="bi bi-caret-right-fill"></i> {this.props.profile.state}</span>
        } 
        else if (this.props.profile.state === "IN_CREATION"){
            return <span className="badge bg-warning text-dark">{this.props.profile.state}</span>
        }
        else{
            return <span className="badge bg-secondary text-light">{this.props.profile.state}</span>
        }
    }

    updateProfile(){
      this.props.openModal(
        this.props.profile.iccid,
        this.props.profile?.subscriptionAddress?.imsi,
        this.props.profile?.subscriptionAddress?.msisdn,
        this.props.profile?.pol2Rules
      );
    }

    render(){
        console.log("this.props", this.props)
        let rowColor = "";
        if (this.props.profile.state === "ENABLED"){
            rowColor = "success";
        }

        return (
            <tr className={'table-' + rowColor}>
                <td className="align-middle" data-cy="state">{this.renderProfileState()}</td>
                <td className="align-middle" data-cy="iccid">{this.props.profile.iccid}</td>
                <td className="align-middle" data-cy="profile-type">{this.props.profile?.profileType}</td>
                <td className="align-middle" data-cy="fallback">{this.props.profile.fallbackAttribute === true ? "True" : "False"}</td>
                {/* <td className="align-middle">{this.renderGroup()}</td> */}
                <td className="align-middle" data-cy="imsi">{this.props.profile?.subscriptionAddress?.imsi}</td>
                <td className="align-middle" data-cy="msisdn">{this.props.profile?.subscriptionAddress?.msisdn}</td>
                <td className="align-middle" data-cy="policy-rule">{this.renderPol2Rules()}</td>
                <td className="align-middle"><Button className="btn btn-circle btn-sm rps-action-btn btn-primary" data-tid='Update Profile' onClick={e => this.updateProfile()}><i className={`bi-pencil-square`}/></Button></td>

            </tr>
        )
    }
};

export default ProfileItem;
